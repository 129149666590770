<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-overlay :show="userLoading">
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Organization" vid="org_id">
              <b-form-group
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('organogram.org')}}
              </template>
              <b-form-select
                plain
                v-model="formData.org_id"
                :options="orgList"
                id="org_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Office Type" vid="office_type_id">
              <b-form-group
                label-for="office_type_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('license_management.office_type')}}
              </template>
              <b-form-select
                plain
                v-model="formData.office_type_id"
                :options="officeTypeList"
                id="office_type_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Office" vid="office_id">
              <b-form-group
                label-for="office_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.office')}}
              </template>
              <b-form-select
                plain
                v-model="formData.office_id"
                :options="officeList"
                id="office_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Designation" vid="designation_id">
              <b-form-group
                label-for="designation_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('bazarMonitoring.designation')}}
              </template>
              <b-form-select
                plain
                v-model="formData.designation_id"
                :options="designationList"
                id="designation_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" md="4" sm="4" xs="12">
            <ValidationProvider name="Employee Name" vid="employee_id">
              <b-form-group
                label-for="employee_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('concernManagement.employee_name')}}
              </template>
              <b-form-select
                plain
                v-model="formData.employee_id"
                :options="userList"
                id="employee_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
              <template v-slot:first>
               <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
        </b-row>
          <template>
            <div style="font-size:18px; background-color: #17a2b8;border-radius:5px" class="p-1">
              <h5 class="text-white text-center">
                {{ $t('concernManagement.contact_info') }}
              </h5>
            </div>
          </template>
          <br/>
          <b-row>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Subject" vid="subject_id" rules="required|min_value:1">
                  <b-form-group
                    label-for="subject_id"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('concernManagement.subject')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="formData.subject_id"
                    :options="subjectList"
                    id="subject_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Photo" vid="photo" rules="required">
                  <b-form-group
                    label-for="photo"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('concernManagement.photo') }} <span class="text-danger">*</span><span style="font-size:10px" class="text-danger">{{ $t('rjscAdminPortal.image_status') }}</span>
                  </template>
                  <b-input-group>
                    <b-form-file
                      validate="size:10"
                      id="photo"
                      v-model="formData.photo"
                      @change="onChange"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Choose a file or drop it here"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  <!-- <b-input-group-append v-if="form.thumbnail">
                      <a target="_blank" :href="rjscServiceBaseUrl + 'storage/' + form.thumbnail" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                    </b-input-group-append> -->
                  </b-input-group>
                  <div class="d-block invalid-feedback">
                    {{ errors[0] }}
                    {{ fileValidationMsz }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Telephone" vid="telephone" rules="required" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="telephone">
                <template v-slot:label>
                  {{ $t('concernManagement.telephone') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="telephone"
                  v-model="formData.telephone"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Mobile" vid="mobile" rules="required|digits:11" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="mobile">
                <template v-slot:label>
                  {{ $t('concernManagement.mobile') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="mobile"
                  v-model="formData.mobile"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Email" vid="email" rules="required" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="email">
                <template v-slot:label>
                  {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="email"
                  id="email"
                  v-model="formData.email"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Website" vid="website" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="website">
                <template v-slot:label>
                  {{ $t('concernManagement.website') }}
                </template>
                <b-form-input
                  id="website"
                  v-model="formData.website"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Address" vid="address" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="address"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.address_en')}} <span class="text-danger">*</span>
                </template>
                  <b-form-textarea
                    id="address"
                    v-model="formData.address"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Address(Bn)" vid="address_bn" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="address_bn"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                </template>
                  <b-form-textarea
                    id="address_bn"
                    v-model="formData.address_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Expertise Details (En)" vid="expertise_detail" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="expertise_detail"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('concernManagement.expertise_details')}}<span class="text-danger">*</span>
                </template>
                  <b-form-textarea
                    id="expertise_details"
                    v-model="formData.expertise_detail"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
              <ValidationProvider name="Expertise Details (Bn)" vid="expertise_detail_bn" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="expertise_detail_bn"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('concernManagement.expertise_details_bn')}}<span class="text-danger">*</span>
                </template>
                  <b-form-textarea
                    id="expertise_detail_bn"
                    v-model="formData.expertise_detail_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
        </b-overlay>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { concennOfficerProfileUpdate, officeListByDesignationApi } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id', 'items'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        subject_id: 0,
        photo: '',
        telephone: '',
        mobile: '',
        expertise_detail: '',
        expertise_detail_bn: '',
        address: '',
        address_bn: '',
        website: ''
      },
      fileValidationMsz: '',
      officeTypeList: [],
      designationList: [],
      officeList: [],
      userList: [],
      userLoading: false
    }
  },
  created () {
    this.officeTypeList = this.getOfficeTypeList(4)
    if (this.id) {
      const tmp = this.getCreteOfficerId()
      this.formData = tmp
    }
  },
  computed: {
    subjectList: function () {
      return this.$store.state.TradeTariffService.commonObj.subjectList.filter(item => item.status === 1)
    },
    orgList () {
      const list = this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.value === 4)
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  watch: {
    'formData.office_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.officeList = this.getOfficeList(newVal)
      } else {
        this.officeList = []
      }
    },
    'formData.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      } else {
        this.designationList = []
      }
    },
    'formData.designation_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.userList = this.getUserList(newVal)
      } else {
        this.userList = []
      }
    }
  },
  methods: {
     async getUserList (designationId) {
      this.userLoading = true
        const result = await RestApi.getData(authServiceBaseUrl, `${officeListByDesignationApi}/${designationId}`)
        this.userLoading = false
          if (result) {
            this.userList = result.map((obj, index) => {
              if (obj.value === this.formData.employee_id) {
                return Object.assign({}, obj, {
                  value: obj.value,
                  text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                })
              }
            })
          } else {
            this.userList = []
          }
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === officeTypeId)
      return officeList
    },
    getDesignationList (officeId) {
      const list = this.$store.state.CommonService.commonObj.assignDesignationList.filter(item => item.office_id === officeId && item.office_type_id === this.formData.office_type_id && item.org_id === this.formData.org_id)
      return list.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    onChange (e) {
      this.fileValidationMsz = ''
      const input = event.target
      const file = input.files[0]
      if (file.size > 1024 * 2048) {
        e.preventDefault()
        this.fileValidationMsz = 'Maximum file size must be 2MB'
      }
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.formData.photo = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.formData.photo = ''
      }
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${concennOfficerProfileUpdate}/${this.id}`, this.formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCreteOfficerId () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
