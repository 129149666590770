// Desingation Wise User List
export const officeListByDesignationApi = 'user/users-by-designation-id'
const createConcern = '/concern-officer-management/create-concern/'
export const createConcernOfficerList = createConcern + 'list'
export const createMakeConsultant = createConcern + 'make-consultant'
export const makeConsultantList = createConcern + 'consultant-list'
// concern Officer List
const concernOfficer = '/concern-officer-management/concern-officer/'
export const makeConcernOfficerList = concernOfficer + 'consultant-officer-list'
export const concennOfficerProfileUpdate = concernOfficer + 'profile-update'
export const consultantToggle = concernOfficer + 'consultant-toggle'
// make schedule
const makeSchedule = '/concern-officer-management/make-schedule/'
export const makeScheduleList = makeSchedule + 'list'
export const makeScheduleCreateUpdate = makeSchedule + 'create-schedule'
export const makeScheduleReject = makeSchedule + 'reject'
// Confirm Appointment
const confirmAppointment = '/concern-officer-management/confirm-appointment/'
export const makeCreateApproveList = confirmAppointment + 'list'
export const makeCreateCancelList = confirmAppointment + 'cancel'
export const makeCreateComplete = confirmAppointment + 'complete'
export const endAppointmentApi = confirmAppointment + 'end-appointment'
